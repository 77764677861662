"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.zip = zip;
/**
 * Throws if there is a length mismatch in the input arrays.
 *
 * @see https://lodash.com/docs#zip
 * @param arrays
 */
function zip(...arrays) {
    var _a;
    const lenEntries = (_a = arrays[0]) === null || _a === void 0 ? void 0 : _a.length;
    if (lenEntries === undefined) {
        return [];
    }
    for (const array of arrays) {
        if (array.length !== lenEntries) {
            throw new Error(`array zip: An input array has an unexpected length. got: ${array.length}; expected: ${lenEntries}`);
        }
    }
    const result = new Array(lenEntries);
    for (let i = 0; i < lenEntries; i += 1) {
        const entry = new Array(arrays.length);
        for (let j = 0; j < arrays.length; j += 1) {
            entry[j] = arrays[j][i];
        }
        result[i] = entry;
    }
    return result;
}
