import { escapeRegExp } from "../regExp.js";
/**
 * Get value of single query string parameter.
 *
 * @param uri uri to parse
 * @param key key to return value for
 * @returns value
 */
export default function getQueryStringParameter(uri, key) {
    const match = new RegExp("(?:^|[?&])" + escapeRegExp(key) + "=(.*?)(?:&|$)", "i").exec(uri);
    return (match === null || match === void 0 ? void 0 : match[1]) ? decodeURI(match[1]) : null;
}
