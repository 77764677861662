import { ensureNonNullable } from "../../nonNullable.js";
export default function loadJS(src, cb) {
    const head = ensureNonNullable(document.getElementsByTagName("head")[0]);
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.charset = "utf-8";
    script.async = true;
    script.defer = true;
    script.src = src;
    // Attach handlers for all browsers
    let done = false;
    /*eslint-disable space-before-function-paren*/
    // @ts-expect-error
    script.onload = script.onreadystatechange = function () {
        if (!done &&
            // @ts-expect-error
            (!script.readyState ||
                // @ts-expect-error
                script.readyState === "loaded" ||
                // @ts-expect-error
                script.readyState === "complete")) {
            done = true;
            if (typeof cb === "function") {
                cb();
            }
        }
    };
    head.appendChild(script);
}
