import getFunctionDisplayName from "./function/getFunctionDisplayName.js";
export function isNonNullable(val) {
    return val !== null && val !== undefined;
}
export { isNonNullable as is };
export function assertNonNullable(value, msg = "NonNullable assertion failure on value") {
    if (value === null || value === undefined) {
        throw new Error(msg);
    }
}
export { assertNonNullable as assert };
export function ensureNonNullable(value, msg = "NonNullable assertion failure on value") {
    if (value === null || value === undefined) {
        throw new Error(msg);
    }
    return value;
}
export { ensureNonNullable as ensure };
export function createUnwrapOr(or) {
    function unwrapOr(val) {
        if (val !== null && val !== undefined) {
            return val;
        }
        return or;
    }
    return unwrapOr;
}
export function mapNonNullable(value, mapFn) {
    if (value === null || value === undefined) {
        return value;
    }
    return mapFn(value);
}
export { mapNonNullable as map };
export function createMapNonNullable(mapFn) {
    const createMapNonNullableInner = (value) => {
        if (value === null || value === undefined) {
            return value;
        }
        return mapFn(value);
    };
    createMapNonNullableInner.displayName = `createMapNonNullable_${getFunctionDisplayName(mapFn)}`;
    return createMapNonNullableInner;
}
export { createMapNonNullable as createMap };
