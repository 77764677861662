import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef } from "react";
import { useId } from "react-aria";
import { translate } from "../helpers/i18n";
/**
 * Same as `QueryInput` but with the label prepended and visible. Not possible to change
 * `QueryInput` into something like this due to leaky abstractions
 * (leaking internas like classnames)
 **/
function QueryInputWithLabel({ label, placeholder, query, onChange }) {
    const inputRef = useRef(null);
    const handleInput = useCallback((e) => onChange(e.target.value), [onChange]);
    const handleReset = useCallback(() => {
        var _a;
        onChange("");
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [onChange, inputRef]);
    const inputId = useId();
    return (_jsxs("fieldset", { className: "ms3-query-input-with-label", children: [_jsx("label", { className: "ms3-query-input-with-label__label", htmlFor: inputId, children: label }), _jsxs("div", { className: "ms3-query-input-with-label__input-container", children: [_jsx("input", { id: inputId, ref: inputRef, className: "ms3-query-input-with-label__input", placeholder: placeholder, type: "search", value: query, onChange: handleInput, autoComplete: "off" }), query !== "" && (_jsx("button", { className: "ms3-query-input-with-label__reset-button", type: "button", onClick: handleReset, children: _jsx("span", { className: "ms3-visuallyhidden", children: translate("ui.query-input.reset") }) }))] })] }));
}
export default QueryInputWithLabel;
