"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unreachable = unreachable;
exports.unreachableValue = unreachableValue;
function unreachable(msg = "Reached an unreachable") {
    throw new Error(msg);
}
function unreachableValue(val, msg = "Reached an unreachable") {
    throw new Error(`${msg} with value "${JSON.stringify(val)}"`);
}
