function LayerLegendItemIcon({iconUrl, label}) {
	return (
		<div>
			<img alt="" className="vmzhb-layer-legend__icon" draggable="false" src={iconUrl}/>
			<span className="vmzhb-layer-legend__label">{label}</span>
		</div>
	);
}

function LayerLegendItemSvg({children, label}) {
	return (
		<div>
			<svg viewBox="0 0 20 20" className="vmzhb-layer-legend__icon">
				{children}
			</svg>
			<span className="vmzhb-layer-legend__label">{label}</span>
		</div>
	);
}

const legends = {
	osm: () => (
		<>
			<LayerLegendItemSvg label="Autobahn">
				<rect x="0" y="0" width="20" height="20" fill="#7797cf"/>
				{/* middle line */}
				<rect x="0" y="8" width="20" height="4" fill="#d7d4da"/>
			</LayerLegendItemSvg>
			<LayerLegendItemSvg label="Bundesstr.">
				{/* border */}
				<rect x="0" y="0" width="20" height="20" fill="#dcbd73"/>
				<rect x="0" y="3" width="20" height="14" fill="#b9af94"/>
			</LayerLegendItemSvg>
			<LayerLegendItemSvg label="Landes- oder Kreisstr.">
				{/* border */}
				<rect x="0" y="0" width="20" height="20" fill="#bfbc93"/>
				<rect x="0" y="3" width="20" height="14" fill="#e2da88"/>
			</LayerLegendItemSvg>
		</>
	),
	traffic: () => (
		<>
			<LayerLegendItemSvg label="frei">
				<rect x="0" y="5" width="20" height="10" fill="#00B31A"/>
			</LayerLegendItemSvg>
			<LayerLegendItemSvg label="zähfließend">
				<rect x="0" y="5" width="20" height="10" fill="#FF9C04"/>
			</LayerLegendItemSvg>
			<LayerLegendItemSvg label="stockend, Stau">
				<rect x="0" y="5" width="20" height="10" fill="#FF0000"/>
			</LayerLegendItemSvg>
		</>
	),
	trafficMessagesAll: () => (
		<>
			<LayerLegendItemIcon iconUrl="/assets/img/mapsight-icons-svg/meldung-plain.svg" label="Störung"/>
			<LayerLegendItemIcon iconUrl="/assets/img/mapsight-icons-svg/stau-plain.svg" label="Stau"/>
			<LayerLegendItemIcon iconUrl="/assets/img/mapsight-icons-svg/vollsperrung-plain.svg" label="Sperrung"/>
			<LayerLegendItemIcon iconUrl="/assets/img/mapsight-icons-svg/baustelle-plain.svg" label="Baustelle"/>
		</>
	),
	trafficMessagesBremenUmzu: () => legends.trafficMessagesAll(),
	trafficMessagesBremenUmzuPreview: () => legends.trafficMessagesAll(),
	constructionWork: () => (
		<>
			<LayerLegendItemIcon iconUrl="/assets/img/mapsight-icons-svg/baustelle-plain.svg" label="Baustelle"/>
			<LayerLegendItemIcon iconUrl="/assets/img/mapsight-icons-svg/fahrbahnverengung-plain.svg" label="Verengung"/>
			<LayerLegendItemIcon iconUrl="/assets/img/mapsight-icons-svg/vollsperrung-plain.svg" label="Sperrung"/>
			<LayerLegendItemIcon iconUrl="/assets/img/mapsight-icons-svg/einbahnstrasse-plain.svg"
							label="Sperrung Einfahrt"/>
		</>
	),
	constructionWorkPreview: () => legends.constructionWork(),
	constructionWorkArchive: () => legends.constructionWork(),
	constructionWorkDetours: () => (
		<>
			<LayerLegendItemIcon iconUrl="/assets/img/mapsight-icons-svg/umleitung-plain.svg" label=" Anfang Umleitung"/>
			<LayerLegendItemSvg label="Umleitungsstrecke">
				<rect x="0" y="5" width="20" height="10" fill="#2badea"/>
			</LayerLegendItemSvg>
		</>
	),
	trafficLightFailures: () => (
		<>
			<LayerLegendItemIcon iconUrl="/assets/img/mapsight-icons-svg/ampel-plain.svg" label="Ampelstörung"/>
		</>
	),
	parking: () => (
		<>
			<LayerLegendItemIcon iconUrl="/assets/img/mapsight-icons-svg/parkflaeche-plain.svg" label="Parkplatz"/>
			<LayerLegendItemIcon iconUrl="/assets/img/mapsight-icons-svg/parkhaus-plain.svg" label="Parkhaus"/>
			<LayerLegendItemSvg label="gering">
				<rect x="5" y="10" width="10" height="10" fill="#00B31A"/>
			</LayerLegendItemSvg>
			<LayerLegendItemSvg label="hoch">
				<rect x="5" y="5" width="10" height="15" fill="#FF9C04"/>
			</LayerLegendItemSvg>
			<LayerLegendItemSvg label="voll">
				<rect x="5" y="0" width="10" height="20" fill="#FF0000"/>
			</LayerLegendItemSvg>
		</>
	),
	parkAndRide: () => (
		<>
			<LayerLegendItemIcon iconUrl="/assets/img/mapsight-icons-svg/pundr-plain.svg" label="P+R-Anlage"/>
			<LayerLegendItemSvg label="gering">
				<rect x="5" y="10" width="10" height="10" fill="#00B31A"/>
			</LayerLegendItemSvg>
			<LayerLegendItemSvg label="hoch">
				<rect x="5" y="5" width="10" height="15" fill="#FF9C04"/>
			</LayerLegendItemSvg>
			<LayerLegendItemSvg label="voll">
				<rect x="5" y="0" width="10" height="20" fill="#FF0000"/>
			</LayerLegendItemSvg>
		</>
	),
	poisTruckInformation: () => (
		<>
			<LayerLegendItemIcon iconUrl="/assets/img/mapsight-icons-svg/hoehenbeschraenkung-plain.svg" label="Durchfahrtshöhe"/>
			<LayerLegendItemIcon iconUrl="/assets/img/mapsight-icons-svg/tonnage-plain.svg" label="Tonnagebeschränkung"/>
		</>
	),
}

const legendKeys = Object.keys(legends);

export function LayerLegend({layerId, variant = "default"}) {
	if (legendKeys.includes(layerId)) {
		return (
			<div className={`vmzhb-layer-legend vmzhb-layer-legend--${variant}`}>
				{legends[layerId]()}
			</div>
		);
	}

	return null;
}
